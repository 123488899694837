import Router from 'next/router'
import 'url-polyfill'
import { CompanyData, TagData } from '../types/api'
import {
  Article,
  ArticleCommon,
  CompanySummary,
  CustomerInterview,
  FinanceNews,
  Organization,
  ContentfulNewTag,
} from './../types'
import { isBrowser } from './browser'
import { convertToDateString, unixtimeUTC } from './date'
import { TagSuggestItem } from '@/components/header/molucule/SuggestItem'
import { TagInBenchmark } from './api/company/domesticBenchmark'

export const facebookShareUrl = (url: string) => {
  return `https://www.facebook.com/sharer/sharer.php?u=${url}`
}

export const host = isBrowser ? location.origin : process.env.CLOUDFRONT_HOST

export const articlesPath = `/articles`

export const articlesHref = articlesPath

export const featuredIndexPath = articlesPath + '#featured'
export const articlesUrl = `${host}${articlesPath}`

export const articlePath = (article: Article | ArticleCommon) =>
  `/articles/${article.slug ? article.slug : article.id}`

export const articleUrl = (article: Article | ArticleCommon) =>
  `${host}${articlePath(article)}`

export const ARTICLE_HREF = '/articles/[slugOrId]'

// TODO: 新タグのURL定義。将来的にはシンプルにtagUrlとリネームする
export const newTagPath = (
  tag: TagData | ContentfulNewTag | TagSuggestItem | TagInBenchmark
) => {
  return `/tags/${tag.slug ? tag.slug : tag.name}`
}

export const newTagUrl = (tag: TagData) => `${host}${newTagPath(tag)}`

export const TAG_HREF = '/tags/[slug]'

export const reportPath = (slug: string) => `/report/${slug}`

export const REPORT_HREF = '/report/[slug]'

export const reportUrl = (slug: string) => `${host}${reportPath(slug)}`

export const seminarPath = (slug: string) => `/events/${slug}`

export const seminarUrl = (slug: string) => `${host}${seminarPath(slug)}`

export const SEMINAR_HREF = '/events/[slug]'

export const featuredPath = (slug: string) => `/featured/${slug}`

export const featuredUrl = (slug: string) => `${host}${featuredPath(slug)}`

export const featuredHref = (slug: string) => `/featured?slug=${slug}`

export const noticePath = (id: string) => `/information/${id}`

export const resourcePath = (slug: string) => `/enterprise/resources/${slug}`

export const RESOURCE_HREF = '/enterprise/resources/[slug]'

export const resourceUrl = (slug: string) => `${host}${resourcePath(slug)}`

export const NOTICE_HREF = '/information/[noticeId]'

export const loginPath = '/login'

export const loginUrl = `${host}${loginPath}`

export const logoutPath = '/logout'

export const logoutUrl = `${host}${logoutPath}`

export const myPagePath = '/users/me'

export const myPageUrl = `${host}${myPagePath}`

export const followListPath = '/users/followings'

export const followListUrl = `${host}${followListPath}`

export const passwordResetUrl = `${host}/users/password_reset`

export const downloadHistoryPath = '/download_histories'

export const downloadHistoryUrl = `${host}${downloadHistoryPath}`

export const statusSettingPath = '/status_setting'

export const statusSettingUrl = `${host}${statusSettingPath}`

export const helpUrl = `${host}/help`

export const glossaryUrl = `${host}/pages/glossary`

export const operatingCompanyUrl = 'https://www.uzabase.com/jp/'
export const operatingCompanyCareersUrl = 'https://www.uzabase.com/jp/careers/'
export const operatingCompanyInformationUrl = 'https://www.uzabase.com/jp/info/'

export const touGuestPath = '/tou/guest'

export const touGuestUrl = `${host}${touGuestPath}`

export const privacyPolicyPath = '/pages/privacy-policy'

export const privacyPolicyUrl = `${host}${privacyPolicyPath}`

export const notificationPath = '/topics/notices'

export const notificationUrl = `${host}${notificationPath}`

export const enterpriseUrl = `https://jp.ub-speeda.com/solutions/startup/`

export const trialRequestUrl = `https://jp.ub-speeda.com/solutions/startup/`

export const downloadDocumentUrl = `https://jp.ub-speeda.com/contact/download/`

export const customerInterviewsPath = `/enterprise/customers`

export const enterpriseCirclePath = `/enterprise/circle`
export const enterpriseCircleUrl = `${host}${enterpriseCirclePath}`

export const commmuneUrl = 'https://initial.commmune.com/'

export const CUSTOMER_INTERVIEW_HREF = `${customerInterviewsPath}/[slug]`

export const extModulesPath = '/ext-modules'

export const speedaBusinessInsightsTopUrl = 'https://www.ub-speeda.com/'
export const speedaInnovationInsightsTopUrl = 'https://sp-edge.com/'
export const speedaRandDInsightsTopUrl = 'https://www.ub-speeda.com/tech'
export const speedaCustomerAnalyticsTopUrl = 'https://forcas.ub-speeda.com/'

export const newsPicksTopUrl = 'https://newspicks.com'

export const newsPicksStartupUrl = 'https://newspicks.com/theme-news/9985/'

export const customerInterviewPath = (interview: CustomerInterview) =>
  `${customerInterviewsPath}/${interview.slug}`

export const customerInterviewUrl = (interview: CustomerInterview) =>
  `${host}${customerInterviewPath(interview)}`

export const customerInterviewsUrl = `https://jp.ub-speeda.com/customers/`

export const seminarsUrl = `https://jp.ub-speeda.com/seminar/`

export const resourcesUrl = `https://jp.ub-speeda.com/document/`

export const COMPANY_HREF = '/companies/[shortname]'

export const companyUrl = (company: CompanySummary | CompanyData) =>
  `${host}${company.path}`

export const INVESTOR_HREF = '/investors/[shortname]'

export const investorUrl = (shortname: string) =>
  `${host}/investors/${shortname}`

export const organizationUrl = (org: Organization) => `${host}${org.path}`

export const financeNewsUrl = (financeNews: FinanceNews) =>
  `${host}${financeNews.path}`

export const originalNewsUrl = (financeNews: FinanceNews) => {
  const filteredNews = financeNews.financeNewsMultiParts.filter(
    (news) => news.url !== null
  )
  return filteredNews.length === 0 ? '' : (filteredNews[0].url as string)
}

export const companySearchPath = (keyword: string) =>
  `/companies?keywords=${keyword}`

export const userAdminUrl = (userId: string) => `${host}/admin/users/${userId}`

export const roundDetailUrl = (
  companyShortname: string,
  roundDetailShortname: string
) => {
  const fundingRoundShortname = roundDetailShortname.slice(0, -3)
  return `${host}/companies/${companyShortname}/funding_rounds/${fundingRoundShortname}/round_details/${roundDetailShortname}`
}

export const verticalApiEndpoint = process.env.VERTICAL_API_ENDPOINT ?? ''

export const twitterShareUrl = (
  url: string,
  text: string,
  username?: string | null
) => {
  const shareText = trimToTweet(url, text, username)
  return `http://twitter.com/share?url=${url}&text=${encodeURIComponent(
    shareText
  )}&via=${twitterUserName}`
}

export const addingSmartroundProspectManagementStartupListUrl = (param: {
  name: string
  shortname: string
  corporateNumber: string
}) => {
  return `https://www.smartround.com/3rdParty/initial/prospectManagement/addStartupToList?name=${param.name}&initialId=${param.shortname}&corporateNumber=${param.corporateNumber}`
}

export const twitterUserName = 'speeda_ub'

const trimToTweet = (url: string, text: string, username?: string | null) => {
  const ellipses = '...'
  const full = username ? `${text} @${username}` : text
  const charactersLimit = Math.floor(
    (280 -
      [`via @${twitterUserName}`, ellipses, url, `@${username}`].join(' ')
        .length) /
      2
  )
  const shorten = `${text.slice(0, charactersLimit)}${ellipses}`
  return full.length > charactersLimit
    ? username
      ? `${shorten} @${username}`
      : `${shorten}`
    : full
}

export const withProtocol = (url: string) => {
  return url.replace(/^\/\//, 'https://')
}

export const redirectToTop = () => {
  Router.push('/')
}

export const isOriginUrl = (url: string) => {
  return url.substring(0, host?.length) === host
}

export const redirectTo = (url: string) => {
  return (location.href = extractPath(url))
}

export const extractPath = (raw: string) => {
  const url = new URL(raw)
  return `${url.pathname}${url.search}`
}

const UTM_PARAM_MAPPING: any = {
  utm_source: 'marketing_source__c',
  utm_medium: 'marketing_medium__c',
  utm_campaign: 'marketing_campaign__c',
}

export const getUtmParams = (raw: string) => {
  const url = new URL(raw)
  const params = new URLSearchParams(url.search)
  const extracted: any = {}
  // @ts-ignore
  for (let [key, _value] of params) {
    if (Object.keys(UTM_PARAM_MAPPING).includes(key)) {
      const name = UTM_PARAM_MAPPING[key]
      extracted[name] = params.get(key)
    }
  }
  return extracted
}

export const toQueryParams = (obj: object = {}) => {
  return Object.entries(obj)
    .filter(([_k, v]) => v !== null && v !== undefined)
    .reduce((result, [k, v], i) => {
      const divider = `${i > 0 ? '&' : ''}`
      const pair = `${k}=${encodeURIComponent(v)}`
      return (result += `${divider}${pair}`)
    }, '')
}

export const addQueryParam = (
  inputUrl: string,
  queryParam: { [k: string]: string }
) => {
  const url = new URL(inputUrl)
  const defaultParamObject = new URLSearchParams(url.search)
  const inputParamObject = new URLSearchParams(queryParam)
  inputParamObject.forEach((v, k) => {
    defaultParamObject.append(k, v)
  })
  return (
    url.protocol +
    '//' +
    url.hostname +
    url.pathname +
    '?' +
    defaultParamObject.toString()
  )
}

export const addNewsPicksTrackingParam = (
  url: string,
  newsId?: number | null
) => {
  const jstNow = unixtimeUTC() + 60 * 60 * 9 * 1000
  const parameterDate = convertToDateString(jstNow, 'yyMMdd')
  const campaignParam = newsId
    ? `ubin${parameterDate}_ns${newsId}`
    : `ubin${parameterDate}`
  const invokerParam = `ln_initial_article_${campaignParam}`
  return addQueryParam(url, {
    ref: 'picked-news_9985',
    utm_source: 'initial',
    utm_medium: 'article',
    utm_campaign: campaignParam,
    invoker: invokerParam,
  })
}

export const isCurrentPathIncluded = (
  currentPath: string,
  pathRegStrList: string[]
) => pathRegStrList.some((path) => new RegExp(path).test(currentPath))

export const extractUrls = (text: string) =>
  text.split('\n').filter((url) => !!url)

export const resizeBannerImageUrl = (
  bannerImageUrl: string,
  width: number,
  height: number
): string => {
  if (bannerImageUrl === '/static/images/top/np-feed-blank-banner.jpg') {
    return bannerImageUrl
  }

  const url = new URL(bannerImageUrl)
  url.searchParams.set('width', width.toString())
  url.searchParams.set('height', height.toString())
  return url.href
}
